import React from "react";
import Layout from "../../components/layout/layout";

const Press = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <div className="max-w-4xl px-2 mx-auto sm:w-4/5">
          {/* <h1 className="text-swa-3">Press Page</h1>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p> */}

          <section className="my-8 space-y-6">
            <h2 className="mb-2 text-swa-3">Press release</h2>
            <div>
              <h3>46th SWA Annual Charity Gala 2023</h3>
              <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
              <a href="/press/gala2023">
                <button className="mt-2 mb-5 btn-dark ">Read more</button>
              </a>
            </div>
            <div>
              {/* <h3>Press release 2</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde,
                error consectetur maiores minus, ut impedit cumque, expedita non
                quis dignissimos magnam. Nesciunt accusantium repellat
                repudiandae. Numquam dicta ad placeat iste?
              </p> */}
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Press;
